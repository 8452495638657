
    @import "config.scss";
 
.container {
  display: flex;
  width: 6rem;
  color: black;
  font-size: 10px;
  height: 35px;
  align-items: center;
  justify-content: space-evenly;
  padding: 15px 0;
  margin-right: 0.5rem;

  @include for-desktop-up {
    margin-bottom: 0;
    grid-column: span 1;
  }
}

.max {
  cursor: not-allowed;
  opacity: 0.3;
}

.min {
  cursor: not-allowed;
  opacity: 0.3;
}
