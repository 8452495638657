
    @import "config.scss";
 
.storeBranchList {
  .item {
    margin: 15px;
    // @extend %subtitle-2;
    color: var(--color-white);
  }
  .item span:first-child {
    font-weight: bold;
  }

  span {
    display: flex;
    margin: 5px;
    text-align: left;
  }
}

.button {
  // border: thin solid var(--color-primary-pag);
  background-color: transparent;
  cursor: pointer;
  margin: 10px;
  border-radius: 15px;
}

.button.active {
  border-left: 5px solid var(--color-primary);
  color: var(--color-primary);
}
