
    @import "config.scss";
 
.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  min-height: 54px;

  ::-webkit-scrollbar {
    display: none;
  }
}
.dots {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
}
.chevrons {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  background-color: #282828cc;
  position: absolute;
  z-index: 2;
  width: 33px;
  height: 45px;
  border-radius: 5px;
}
.withoutBackgroundChevron {
  background-color: transparent;
}

.chevronHighlight {
  font-size: 4rem;
  background: #121212;
  opacity: 0.7;
  // border-radius: 0 15px 15px 0;
}

.slider {
  width: fit-content;
  height: 100%;
  left: 5%;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  transition: scroll 1s;
  scroll-snap-type: x mandatory;
}

.sliderContainer {
  display: flex;
  gap: 0.5rem;
}

.bannerWrapper {
  height: 100%;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  scroll-snap-align: start;
}

.logos {
  z-index: var(--z-content);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoImg {
  display: flex;
  justify-content: center;
  height: 25px;
  width: 47vw;

  img {
    height: 100%;
    width: 100%;
  }
}

.img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  img {
    height: 99%;
    width: 100%;
  }
}
