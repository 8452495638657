
    @import "config.scss";
 
.viewers {
  width: 70px;
  padding-block: 4px;
  background: #ffffffbf;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.liveLogo {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  left: 10px;
  background-color: #ad0000;
  min-width: 70px;
  border-radius: 5px;
}
