
    @import "config.scss";
 
.back {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  opacity: 0.6;
  z-index: 10;
}

.container {
  padding: 0.5rem 1rem;
  position: absolute;
  background: var(--color-grey-dark);
  border-radius: 15px;
  z-index: var(--z-modal);
  max-width: 34rem;
  top: 45px;
  left: auto;
  right: 0;
  height: auto;
  width: 92vw;
  color: white;
  height: 462px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-block: 5px;
    gap: 10px;
  }
  .loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
}
