
    @import "config.scss";
 
.dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  padding: 0.5rem;
  border-radius: 30px;
  overflow-y: scroll;
  z-index: var(--z-content);

  @include for-desktop-up {
    top: 0;
    left: 0;
    bottom: 0;
    transform: none;
    flex-direction: column;
  }

  img {
    border-radius: var(--br-soft);
  }

  .noSelected {
    opacity: 0.6;
  }
  .selected {
    border: 2px solid var(--color-primary);
    opacity: 1;
  }

  .dot {
    background: transparent;
    border: none;
    cursor: pointer;
  }
}

.sliderContainer {
  display: flex;
  height: 100%;
}

.bannerWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
  flex: 0 0 auto;
}

.logos {
  z-index: var(--z-content);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoImg {
  display: flex;
  justify-content: center;
  height: 25px;
  width: 47vw;

  img {
    height: 100%;
    width: 100%;
  }
}

.img {
  height: 100%;
  width: 100%;
  max-height: 400px;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}
