
    @import "config.scss";
 
.container {
  padding-inline: 0.5rem;
  padding-block: 25px;

  a {
    scroll-snap-align: start;
  }

  .title {
    padding-inline: 1rem;
    margin-bottom: 25px;

    @include for-desktop-up {
      display: flex;
      justify-content: space-between;
    }
  }
  .withLink {
    display: flex;
    justify-content: space-between;
  }
}
