
    @import "config.scss";
 
.back {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  opacity: 0.6;
  z-index: 10;
}

.container {
  padding: 0.5rem 1rem;
  position: absolute;
  background: var(--color-grey-dark);
  border-radius: 15px;
  z-index: var(--z-modal);
  max-width: 34rem;
  top: 45px;
  left: auto;
  right: 0;
  height: auto;
  width: 92vw;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-block: 5px;
    gap: 10px;
  }

  .empty {
    display: flex;
    flex-direction: column;
    margin-block: 15px;
    color: white;
    min-width: 300px;
  }

  .resumen {
    border-radius: 15px;
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: start;
  }

  .items {
    max-height: 45vh;
    overflow-y: scroll;
    margin-block: 1rem;
    & > *:first-child {
      margin-top: 0;
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  .icon {
    color: var(--color-white-dark);
    margin-right: 10px;
  }
}
