
    @import "config.scss";
 
.footerLayout {
  background-color: var(--color-footer-bg);
  display: grid;
  gap: 1rem;
  column-gap: 3rem;
  padding-bottom: 3rem;
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  grid-template-columns: 1fr;
  justify-items: center;
}

@include for-desktop-up {
  .footerLayout {
    grid-template-columns: 1fr 1fr;
  }
}
