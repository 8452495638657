
    @import "config.scss";
 
.box {
  display: flex;
  justify-content: space-between;
  color: white;
  width: 100%;
  gap: 20px;
  flex-direction: column;
}

.flex {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.boxCharacteristics {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.table {
  color: white;
  //   border-radius: 2em;
  border-spacing: 0;
  border-collapse: separate;
  overflow: hidden;
  // padding: 20px;
  //   width: 100%;
  width: fit-content;
  border: 1px solid white;
  font-size: 0.875rem;

  @include for-desktop-up {
    min-width: 34rem;
  }

  & * {
    // border: 1px solid white;
    padding: 0.7rem;
    text-align: start;
    word-break: break-word;
  }

  & th {
    // background-color: #1d1d1d;
    vertical-align: top;
    font-weight: 400;
  }

  & td {
    font-weight: 400;
  }

  & th:not(:last-child),
  & td:not(:last-child) {
    border-right: 1px solid white;
  }

  & > thead > tr:not(:last-child) > th,
  & > thead > tr:not(:last-child) > td,
  & > tbody > tr:not(:last-child) > th,
  & > tbody > tr:not(:last-child) > td,
  & > tfoot > tr:not(:last-child) > th,
  & > tfoot > tr:not(:last-child) > td,
  & > tr:not(:last-child) > td,
  & > tr:not(:last-child) > th,
  & > thead:not(:last-child),
  & > tbody:not(:last-child),
  & > tfoot:not(:last-child) {
    border-bottom: 1px solid white;
    width: 50%;
  }
}
