
    @import "config.scss";
 
.logos {
  display: flex;
  gap: 15px;
  align-items: center;

  p {
    display: none;
  }

  @include for-desktop-up {
    align-items: center;

    p {
      display: block;
    }
  }
}

.secondLine {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 2rem;

  .phoneLogo {
    display: none;
  }

  //   @include for-desktop-up {
  //     display: block;

  //     .phoneLogo {
  //       display: block;
  //     }
  //   }
}

.firstLine {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}

.contact {
  @include for-desktop-up {
    padding-top: 25px;
  }
}
