
    @import "config.scss";
 
.powered {
  font-family: Montserrat;
  color: #d1d3d4;
  font-weight: 500;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  flex-direction: column;
  align-items: center;
  @include for-desktop-up {
    grid-column: 1/3;
  }
}
