
    @import "config.scss";
 
.container {
  padding: 2rem;
  position: absolute;
  background: var(--color-grey);
  z-index: 1000;
  width: 20rem;
  right: -45px;
  top: 50px;
  border-radius: 15px;

  @include for-desktop-up {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 120%;
  }

  .item {
    color: var(--color-white-dark);
    display: flex;
    cursor: pointer;
  }
}
.icon {
  color: var(--color-white-absolute);
  margin-right: 10px;
}

.iconBtn {
  z-index: var(--z-modal-close-icon);
  cursor: pointer;
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 1rem;
  right: 1rem;
  appearance: none;
  border: none;
  background: transparent;
  padding: 0.5rem;
}

.items {
  color: var(--color-white-dark);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal {
  background-color: #181818;
  padding: 2rem;
  width: 95vw;
  border-radius: 15px;
  width: 20rem;
}
