
    @import "config.scss";
 
.modalContainer {
  background-color: var(--color-grey);
  width: 100%;
  padding: 25px;
}

.titles {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.list {
  display: flex;
  width: 50%;
  flex-direction: column;
  height: 40vh;
  overflow: scroll;
}

.info {
  display: flex;
}

.content {
  width: 50%;
  height: 100%;
}
