
    @import "config.scss";
 
.title {
  font-weight: bolder;
  color: var(--color-white);
  padding: 30px 20px;
}
.container {
  min-width: 100%;
}

.select {
  margin-top: 20px;
  margin-bottom: 2rem;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
