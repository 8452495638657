
    @import "config.scss";
 
.container {
  position: relative;
}
.loader {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
