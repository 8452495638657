
    @import "config.scss";
 
.video {
  border-radius: 15px;
  position: relative;
  width: fit-content;
  max-height: 100vh;
  justify-self: center;
  background-color: black;
  video {
    object-fit: cover;
    height: 100vh;
    max-height: 700px;
    max-width: 100%;
  }
  .activateSound {
    background: #00000061;
    padding: 40px 10px;
    width: 80%;
    position: absolute;
    top: 50%;
    font-size: 20px;
    border-radius: 20px;
    text-align: center;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-shadow: rgba(0, 0, 0, 0.9) 2px 2px 4p;
  }
}
